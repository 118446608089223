import * as React from "react";

import { useState, useEffect } from "react";

import {
  Badge,
  Table,
  Box,
  SpaceBetween,
  Button,
  TextFilter,
  Header,
  Pagination,
  CollectionPreferences,
  Link,
} from "@amzn/awsui-components-react/polaris";

import { fetchAllProjects } from "../Utils/ApiCalls/Queries";
import { checkIfUserProjectOwner } from "../Utils/CheckIfUserIsProjectOwner";

interface IFindProjectPageProps {}

const renderDomains = (domains: any) => {
  return domains.map((domain: string, index: number) => (
    <SpaceBetween direction="horizontal" size="xs">
      <Badge color="blue" key={index}>
        {domain}
      </Badge>
    </SpaceBetween>
  ));
};

const renderStatus = (status: string) => {
  return status === "Open" ? (
    <Badge color="green">{status}</Badge>
  ) : (
    <Badge color="red">{status}</Badge>
  );
};

const FindProjectPage = ({ user }: any) => {
  const [projects, setProjects] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const allProjects = await fetchAllProjects();
      // console.log("allProjects: ", allProjects);
      // setProjects(allProjects);
      const filteredProjects = allProjects.filter(
        (project: any) =>
          !checkIfUserProjectOwner(project.owner, user?.username)
      );

      setProjects(filteredProjects);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching projects: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Table
        columnDefinitions={[
          {
            id: "projectName",
            header: "Project Name",
            cell: (e: any) => (
              <Link href={`/project/${e.id}`}>{e.projectName}</Link>
            ),
            sortingField: "projectName",
            isRowHeader: true,
          },
          {
            id: "projectStatus",
            header: "Project Status",
            cell: (e: any) => renderStatus(e.projectStatus),
          },
          {
            id: "projectStartDate",
            header: "Project Start Date",
            cell: (e: any) => e.projectStartDate,
          },
          {
            id: "projectEndDate",
            header: "Project End Date",
            cell: (e: any) => e.projectEndDate,
          },
          {
            id: "projectDomains",
            header: "Project Domains",
            cell: (e: any) => renderDomains(e.projectDomains),
          },
          {
            id: "projectDescription",
            header: "Project Description",
            cell: (e: any) => e.projectDescription,
          },
        ]}
        columnDisplay={[
          { id: "projectName", visible: true },
          { id: "projectStatus", visible: true },
          { id: "projectStartDate", visible: true },
          { id: "projectEndDate", visible: true },
          { id: "projectDomains", visible: true },
          { id: "projectDescription", visible: true },
        ]}
        items={projects}
        loading={loading}
        loadingText="Loading projects"
        stickyHeader
        variant="full-page"
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No projects</b>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter filteringPlaceholder="Find resources" filteringText="" />
        }
        header={<Header variant="awsui-h1-sticky">Find Projects</Header>}
        pagination={<Pagination currentPageIndex={1} pagesCount={1} />}
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              contentDisplay: [
                { id: "projectName", visible: true },
                { id: "projectStatus", visible: true },
                { id: "projectStartDate", visible: true },
                { id: "projectEndDate", visible: true },
                { id: "projectDomains", visible: true },
                { id: "projectDescription", visible: true },
              ],
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" },
              ],
            }}
          />
        }
      />
    </div>
  );
};

export default FindProjectPage;
