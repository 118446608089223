import ProjectsTable from "./ProjectsTable";
const ProjectsTab = (props: any) => {
  return (
    <div>
      <ProjectsTable type={props.type} user={props.user} />
    </div>
  );
};

export default ProjectsTab;
