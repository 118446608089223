import { fetchSme } from "./ApiCalls/Queries";

// CheckIfUserExist function
const CheckIfUserExist = async (userId: string) => {
  const user = await fetchSme(userId);

  return Boolean(user);
};

export default CheckIfUserExist;
