import * as React from "react";
import { useState, useEffect } from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { Multiselect } from "@amzn/awsui-components-react";
import FormatUsername from "../Utils/FormatUsername";
import { useNavigate } from "react-router-dom";

import { fetchAllDomains } from "../Utils/ApiCalls/Queries";
import { createProfileMutation } from "../Utils/ApiCalls/Mutations";

export default (props: any) => {
  const { user, userProfile, setUserProfile } = props;
  const [selectedDomains, setSelectedDomains] = useState<any>([]);
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const domainsResult = await fetchAllDomains();
      setDomains(domainsResult);
    }
    fetchData();
  }, []);

  const handleFormChange = (name: string, value: any) => {
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    console.log("userProfile Submitted: ", userProfile);
    const result = await createProfileMutation(userProfile);
    console.log("result from handleFormat: ", result);
    if (result?.data) {
      window.location.reload();
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link">
              Cancel
            </Button>
            <Button variant="primary">Submit</Button>
          </SpaceBetween>
        }
        header={<Header variant="h1">Complete your SME profile</Header>}>
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="First Name">
              <Input
                value={userProfile?.firstName || ""}
                inputMode="text"
                disabled
              />
            </FormField>
            <FormField label="Last Name">
              <Input
                value={userProfile?.lastName || ""}
                inputMode="text"
                disabled
              />
            </FormField>
            <FormField label="Alias">
              <Input
                value={userProfile?.profileUsername || ""}
                inputMode="text"
                disabled
              />
            </FormField>
            <FormField label="Email">
              <Input
                value={userProfile?.email || ""}
                inputMode="text"
                disabled
              />
            </FormField>
            <FormField
              description="Provide a short bio to your profile."
              label={
                <span>
                  Profile Bio <i>- optional</i>{" "}
                </span>
              }>
              <Textarea
                onChange={({ detail }) => {
                  handleFormChange("profileBio", detail.value);
                }}
                value={userProfile?.profileBio}
              />
            </FormField>
            <FormField
              label="Profile Domains"
              description="Enhance your profile by adding domains in which you are competent.">
              <Multiselect
                selectedOptions={selectedDomains}
                onChange={({ detail }) => {
                  handleFormChange(
                    "profileDomains",
                    detail.selectedOptions.map((option) => option.label)
                  );

                  setSelectedDomains(detail.selectedOptions);
                }}
                options={domains.map((domain: any) => ({
                  label: domain.DomainName,
                  value: domain.id,
                }))}
                placeholder="Select domains"
                tokenLimit={4}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
    </form>
  );
};
