/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://564dz3rblfcevexauszo4fh5b4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-zxi645l4xrboradbzon7663ig4",
    "aws_cognito_identity_pool_id": "us-west-2:39dce4f0-6d35-43a7-9d02-82645d4c7a09",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_S3rTvCVNG",
    "aws_user_pools_web_client_id": "29dh7l4864l8smpatupdp490jf",
    "oauth": {
        "domain": "smesourcerfrontend24fa10af-24fa10af-staging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.sme-sourcer.solutions-architect.aws.dev/,http://localhost:3000/",
        "redirectSignOut": "https://www.sme-sourcer.solutions-architect.aws.dev/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
