import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  ContentLayout,
  ColumnLayout,
  ExpandableSection,
} from "@amzn/awsui-components-react/polaris";

const AboutPage = (props: any) => {
  const { user } = props;
  const { Auth } = props;
  // Michael's edits
  const navigate = useNavigate();

  const navigateToCreateProject = () => {
    navigate("./create/project");
  };

  const navigateToFindSme = () => {
    navigate("./find/sme");
  };

  const navigateToFindProject = () => {
    navigate("./find/projects");
  };

  const navigateToProfile = () => {
    navigate("./user/profile");
  };

  // end Michael's edits

  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
  }, [user]);

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">
            <div className="">
              <Box padding={{ vertical: "xxxl", horizontal: "s" }}>
                <Grid
                  gridDefinition={[
                    { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                    {
                      colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                      offset: { l: 2, xxs: 1 },
                    },
                  ]}
                >
                  <div>
                    <Box
                      variant="h1"
                      fontWeight="heavy"
                      padding="n"
                      fontSize="display-l"
                      color="inherit"
                    >
                      <span className="">About</span>
                    </Box>
                    <Box variant="p" fontWeight="light">
                      <span className="custom-home__category">
                        How to use SME Sourcer
                      </span>
                    </Box>
                  </div>
                </Grid>
              </Box>
            </div>
          </Header>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="m">
        <Container
          header={
            <Header variant="h1" description="">
              Meet The Team
            </Header>
          }
        >
          <div>
            <p>PLACEHOLDER: Input step-by-step instructions for SMEs</p>
            <img
              src={require("../Assets/images/Meet-The-Team.png")}
              alt="Team Diagram"
            />
          </div>
        </Container>
        <Container
          header={
            <Header variant="h1" description="">
              How it works for SMEs
            </Header>
          }
        >
          <div>
            <p>PLACEHOLDER: Input step-by-step instructions for SMEs</p>
            <img
              src={require("../Assets/images/SME-Flow.png")}
              alt="SME Flow Diagram"
            />
          </div>
        </Container>
        <Container
          header={
            <Header variant="h1" description="">
              How it works for Project Owners
            </Header>
          }
        >
          <div>
            <p>
              PLACEHOLDER: Input step-by-step instructions for Project Owners
            </p>
            <img
              src={require("../Assets/images/Project-Owner-Flow.png")}
              alt="Project Owner Flow Diagram"
            />
          </div>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default AboutPage;
