import { SpaceBetween, Badge } from "@amzn/awsui-components-react";

const renderDomains = (domains: any) => {
  console.log("Domains I got to render: ", domains);
  return domains.map((domain: string, index: number) => (
    <SpaceBetween direction="horizontal" size="xs">
      <Badge color="blue" key={index}>
        {domain}
      </Badge>
    </SpaceBetween>
  ));
};

export default renderDomains;
