import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  ContentLayout,
  ColumnLayout,
  ExpandableSection,
} from "@amzn/awsui-components-react/polaris";

const SecurityPage = (props: any) => {
  const { user } = props;
  const { Auth } = props;

  const navigate = useNavigate();

  const navigateToCreateProject = () => {
    navigate("./create/project");
  };

  const navigateToFindSme = () => {
    navigate("./find/sme");
  };

  const navigateToFindProject = () => {
    navigate("./find/projects");
  };

  const navigateToProfile = () => {
    navigate("./user/profile");
  };

  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
  }, [user]);

  return (
    <>
    <p>
    # For security issues related to Amazon Web Services (AWS), please see our security policy
    Policy: https://aws.amazon.com/security/vulnerability-reporting/
    </p>
    <p>
    # To contact AWS regarding a vulnerability
    Contact: mailto:aws-security@amazon.com
    Preferred-Languages: en
    </p>
    <p>
    # We support PGP encryption
    Encryption: https://aws.amazon.com/security/aws-pgp-public-key/
    </p>
    <p>
    # This file expires every 365 days
    Expires: 2024-09-30T18:37:07z
    </p>
    <p>
    # We're hiring - join Amazon Security!
    Hiring: https://www.amazon.jobs/en/business_categories/amazon-security
    </p>
    <p>
    Please route data access and deletion requests to Galaxi.
    </p>
    </>
  );
};

export default SecurityPage;
