import * as React from "react";

import { useState, useEffect } from "react";

import {
  Cards,
  Box,
  SpaceBetween,
  TextFilter,
  Pagination,
  CollectionPreferences,
} from "@amzn/awsui-components-react/polaris";

import {
  TableEmptyState,
  TableNoMatchState,
} from "../Commons/common-components";

import { useCollection } from "@amzn/awsui-collection-hooks";
import { API, Storage, Analytics, graphqlOperation } from "aws-amplify";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import * as queries from "../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import { listProjects } from "../graphql/queries";

import { COLUMN_DEFINITIONS } from "../Commons/homepagecolumns.jsx";
import { useColumnWidths } from "../Commons/use-column-widths";

interface IHomePageProps {}

const ProjectsTable = (props: any) => {
  const { user } = props;
  const { Auth } = props;
  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
    console.log("User", user);
  }, [user]);
  return (
    <Box margin={{ bottom: "l" }}>
      <div>
        <ListOfVideos type={props.type} />
      </div>
    </Box>
  );
};

// Function to setup the table for our list of files that the user has uploaded
function ListOfVideos(props: any) {
  const [columnDefinitions, saveWidths] = useColumnWidths(
    "React-Table-Widths",
    COLUMN_DEFINITIONS
  );
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const [filesToDisplayLength, setFilesToDisplayLength] = useState(0);
  console.log("Checkpoint 0");
  console.log(columnDefinitions[0]);
  console.log("Checkpoint 1");
  const { items, actions, filterProps, paginationProps } = useCollection(
    filesToDisplay,
    {
      filtering: {
        empty: <TableEmptyState resourceName="Files" />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {
        defaultState: {
          sortingColumn: columnDefinitions[0],
          isDescending: true,
        },
      },
      // selection: {},
    }
  );

  useEffect(() => {
    getfilesFromDB();
  }, []);

  // Need to plug in pagination
  async function getfilesFromDB() {
    try {
      const allProjects = (await API.graphql(
        graphqlOperation(listProjects)
      )) as { data: any; errors: any[] };

      console.log("List of all items:");
      console.log(allProjects.data.listProjects.items);

      const files = allProjects.data.listProjects.items;

      console.log(files);
      setFilesToDisplay(files);
      // console.log("SIZE " + files.length);
      setFilesToDisplayLength(files.length);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Table
      columnDefinitions={columnDefinitions}
      columnDisplay={[
        { id: "createDate", visible: false },
        { id: "name", visible: true },
        { id: "startdate", visible: true },
        { id: "enddate", visible: true },
        { id: "projectdomains", visible: true },
        { id: "description", visible: true },
      ]}
      items={items}
      loadingText="Loading files"
      stickyHeader={true}
      stripedRows={true}
      wrapLines
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No Projects available</b>
            <Button href="/create/project" variant="primary">
              Create Project
            </Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search Projects"
          filteringAriaLabel="Filter files"
        />
      }
      header={
        <Header
          counter={
            selectedItems.length
              ? "(" +
                selectedItems.length +
                "/" +
                JSON.stringify(filesToDisplayLength) +
                ")"
              : "(" + JSON.stringify(filesToDisplayLength) + ")"
          }>
          {props.type === "Owned"
            ? "Owned Projects"
            : props.type === "Volunteered"
            ? "Volunteered Projects"
            : null}
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
}

export default ProjectsTable;
