/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../GraphQlApi";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getProfileByProfileUsername = /* GraphQL */ `query GetProfileByProfileUsername($profileUsername: String!) {
  getProfileByProfileUsername(profileUsername: $profileUsername) {
    id
    profileUsername
    firstName
    lastName
    email
    profileBio
    profileDomains
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileByProfileUsernameQueryVariables,
  APITypes.GetProfileByProfileUsernameQuery
>;
export const getDomain = /* GraphQL */ `query GetDomain($id: ID!) {
  getDomain(id: $id) {
    id
    DomainName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDomainQueryVariables, APITypes.GetDomainQuery>;
export const listDomains = /* GraphQL */ `query ListDomains(
  $filter: ModelDomainFilterInput
  $limit: Int
  $nextToken: String
) {
  listDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      DomainName
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDomainsQueryVariables,
  APITypes.ListDomainsQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    projectName
    projectDescription
    projectStartDate
    projectEndDate
    projectStatus
    projectSmes
    projectDomains
    projectRoles {
      items {
        id
        roleName
        roleDescription
        projectId
        project {
          id
          projectName
          projectDescription
          projectStartDate
          projectEndDate
          projectStatus
          projectSmes
          projectDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        assignedTo {
          id
          profileUsername
          firstName
          lastName
          email
          profileBio
          profileDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        projectRoleAssignedToId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectName
      projectDescription
      projectStartDate
      projectEndDate
      projectStatus
      projectSmes
      projectDomains
      projectRoles {
        items {
          id
          roleName
          roleDescription
          projectId
          createdAt
          updatedAt
          projectRoleAssignedToId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getProjectRole = /* GraphQL */ `query GetProjectRole($id: ID!) {
  getProjectRole(id: $id) {
    id
    roleName
    roleDescription
    projectId
    project {
      id
      projectName
      projectDescription
      projectStartDate
      projectEndDate
      projectStatus
      projectSmes
      projectDomains
      projectRoles {
        items {
          id
          roleName
          roleDescription
          projectId
          createdAt
          updatedAt
          projectRoleAssignedToId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    assignedTo {
      id
      profileUsername
      firstName
      lastName
      email
      profileBio
      profileDomains
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    projectRoleAssignedToId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectRoleQueryVariables,
  APITypes.GetProjectRoleQuery
>;
export const listProjectRoles = /* GraphQL */ `query ListProjectRoles(
  $filter: ModelProjectRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      roleName
      roleDescription
      projectId
      project {
        id
        projectName
        projectDescription
        projectStartDate
        projectEndDate
        projectStatus
        projectSmes
        projectDomains
        projectRoles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      assignedTo {
        id
        profileUsername
        firstName
        lastName
        email
        profileBio
        profileDomains
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      projectRoleAssignedToId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectRolesQueryVariables,
  APITypes.ListProjectRolesQuery
>;
export const projectRolesByProjectId = /* GraphQL */ `query ProjectRolesByProjectId(
  $projectId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  projectRolesByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      roleName
      roleDescription
      projectId
      project {
        id
        projectName
        projectDescription
        projectStartDate
        projectEndDate
        projectStatus
        projectSmes
        projectDomains
        projectRoles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      assignedTo {
        id
        profileUsername
        firstName
        lastName
        email
        profileBio
        profileDomains
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      projectRoleAssignedToId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectRolesByProjectIdQueryVariables,
  APITypes.ProjectRolesByProjectIdQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile($id: ID!) {
  getProfile(id: $id) {
    id
    profileUsername
    firstName
    lastName
    email
    profileBio
    profileDomains
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const listProfiles = /* GraphQL */ `query ListProfiles(
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      profileUsername
      firstName
      lastName
      email
      profileBio
      profileDomains
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfilesQueryVariables,
  APITypes.ListProfilesQuery
>;
export const profilesByProfileUsername = /* GraphQL */ `query ProfilesByProfileUsername(
  $profileUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  profilesByProfileUsername(
    profileUsername: $profileUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      profileUsername
      firstName
      lastName
      email
      profileBio
      profileDomains
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProfilesByProfileUsernameQueryVariables,
  APITypes.ProfilesByProfileUsernameQuery
>;
