import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nProvider } from "@amzn/awsui-components-react/polaris/i18n";

import messages from "@amzn/awsui-components-react/polaris/i18n/messages/all.all";

// Add the class to the body element before rendering
document.body.classList.add("awsui-visual-refresh");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nProvider locale="..." messages={[messages]}>
      <App />
    </I18nProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
