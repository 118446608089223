import * as React from "react";

import { useState, useEffect } from "react";

import {
  Cards,
  Box,
  SpaceBetween,
  TextFilter,
  Pagination,
  CollectionPreferences,
} from "@amzn/awsui-components-react/polaris";

import {
  TableEmptyState,
  TableNoMatchState,
} from "../Commons/common-components";

import { useCollection } from '@amzn/awsui-collection-hooks';
import { API, Storage, Analytics, graphqlOperation } from "aws-amplify";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import * as queries from '../graphql/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { listProjects } from "../graphql/queries";

import { COLUMN_DEFINITIONS } from '../Commons/homepagecolumns.jsx';
import { useColumnWidths } from '../Commons/use-column-widths';

interface IHomePageProps {}

const PastProjectsPage = (props: any) => {
  const { user } = props;
  const { Auth } = props;
  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
  }, [user]);
  return (
    <Box margin={{ bottom: 'l' }}>
      <div>
        <ListOfVideos />
      </div>
    </Box>
  );
};

// Function to setup the table for our list of files that the user has uploaded
function ListOfVideos() {
  const [columnDefinitions, saveWidths] = useColumnWidths('React-Table-Widths', COLUMN_DEFINITIONS);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const [filesToDisplayLength, setFilesToDisplayLength] = useState(0);
  console.log("Checkpoint 0")
  console.log(columnDefinitions[0]);
  console.log("Checkpoint 1")
  const { items, actions, filterProps, paginationProps } = useCollection(
    filesToDisplay,
    {
      filtering: {
        empty: <TableEmptyState resourceName="Files" />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: 10 },
      sorting: {
        defaultState: {
          sortingColumn: columnDefinitions[0],
          isDescending: true
        }
      },
      // selection: {},
    }
  );

  useEffect(() => {
    getfilesFromDB()
  }, [])

  // Need to plug in pagination
  async function getfilesFromDB() {

    try {
      /*
      const allProjects = await API.graphql({
        query: listProjects,
      });
      */

      const allProjects = (await API.graphql(graphqlOperation(listProjects))) as { data: any; errors: any[] };

      console.log("List of all items:")
      console.log(allProjects.data.listProjects.items)

      const files = allProjects.data.listProjects.items;
      // Get signed URLS for SRT and VTT files
      /*
      for (var i = 0; i < files.length; i++) {
        // console.log((files[i].createDate));
        if (files[i].srt) {
          // console.log(files[i].srt);
          files[i].srt = await getURLToDownloadFile(files[i].srt);
          // console.log(files[i].srt);
        }
        if (files[i].vtt) {
          // console.log(files[i].vtt);
          files[i].vtt = await getURLToDownloadFile(files[i].vtt);
          // console.log(files[i].vtt);
        }
      }
      */
      console.log(files);
      setFilesToDisplay(files)
      // console.log("SIZE " + files.length);
      setFilesToDisplayLength(files.length);

    } catch (err) { console.log(err) }
  }

  return (

    <Table
      columnDefinitions={columnDefinitions}
      columnDisplay={[
        { id: "createDate", visible: false },
        { id: "name", visible: true },
        { id: "startdate", visible: true },
        { id: "enddate", visible: true },
        { id: "projectdomains", visible: true },
        { id: "description", visible: true },
        // { id: "costSavings", visible: true },
        // { id: "createdate", visible: true },
        // { id: "delete", visible: true }
      ]}
      items={items}
      loadingText="Loading files"
      stickyHeader={true}
      stripedRows={true}
      wrapLines
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>You haven't processed any files yet</b>
            <Button href="/uploadfiles" variant="primary">
              Upload file(s)
            </Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Find files"
          filteringAriaLabel="Filter files"
        />
      }

      header={
        <Header
          counter={
            selectedItems.length
              ? "(" + selectedItems.length + "/" + JSON.stringify(filesToDisplayLength) + ")"
              : "(" + JSON.stringify(filesToDisplayLength) + ")"
          }
        >
          List of Previous Projects
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
}

export default PastProjectsPage;
