/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../GraphQlApi";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createDomain = /* GraphQL */ `mutation CreateDomain(
  $input: CreateDomainInput!
  $condition: ModelDomainConditionInput
) {
  createDomain(input: $input, condition: $condition) {
    id
    DomainName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDomainMutationVariables,
  APITypes.CreateDomainMutation
>;
export const updateDomain = /* GraphQL */ `mutation UpdateDomain(
  $input: UpdateDomainInput!
  $condition: ModelDomainConditionInput
) {
  updateDomain(input: $input, condition: $condition) {
    id
    DomainName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDomainMutationVariables,
  APITypes.UpdateDomainMutation
>;
export const deleteDomain = /* GraphQL */ `mutation DeleteDomain(
  $input: DeleteDomainInput!
  $condition: ModelDomainConditionInput
) {
  deleteDomain(input: $input, condition: $condition) {
    id
    DomainName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDomainMutationVariables,
  APITypes.DeleteDomainMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    projectName
    projectDescription
    projectStartDate
    projectEndDate
    projectStatus
    projectSmes
    projectDomains
    projectRoles {
      items {
        id
        roleName
        roleDescription
        projectId
        project {
          id
          projectName
          projectDescription
          projectStartDate
          projectEndDate
          projectStatus
          projectSmes
          projectDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        assignedTo {
          id
          profileUsername
          firstName
          lastName
          email
          profileBio
          profileDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        projectRoleAssignedToId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    projectName
    projectDescription
    projectStartDate
    projectEndDate
    projectStatus
    projectSmes
    projectDomains
    projectRoles {
      items {
        id
        roleName
        roleDescription
        projectId
        project {
          id
          projectName
          projectDescription
          projectStartDate
          projectEndDate
          projectStatus
          projectSmes
          projectDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        assignedTo {
          id
          profileUsername
          firstName
          lastName
          email
          profileBio
          profileDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        projectRoleAssignedToId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    projectName
    projectDescription
    projectStartDate
    projectEndDate
    projectStatus
    projectSmes
    projectDomains
    projectRoles {
      items {
        id
        roleName
        roleDescription
        projectId
        project {
          id
          projectName
          projectDescription
          projectStartDate
          projectEndDate
          projectStatus
          projectSmes
          projectDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        assignedTo {
          id
          profileUsername
          firstName
          lastName
          email
          profileBio
          profileDomains
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        projectRoleAssignedToId
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createProjectRole = /* GraphQL */ `mutation CreateProjectRole(
  $input: CreateProjectRoleInput!
  $condition: ModelProjectRoleConditionInput
) {
  createProjectRole(input: $input, condition: $condition) {
    id
    roleName
    roleDescription
    projectId
    project {
      id
      projectName
      projectDescription
      projectStartDate
      projectEndDate
      projectStatus
      projectSmes
      projectDomains
      projectRoles {
        items {
          id
          roleName
          roleDescription
          projectId
          createdAt
          updatedAt
          projectRoleAssignedToId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    assignedTo {
      id
      profileUsername
      firstName
      lastName
      email
      profileBio
      profileDomains
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    projectRoleAssignedToId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectRoleMutationVariables,
  APITypes.CreateProjectRoleMutation
>;
export const updateProjectRole = /* GraphQL */ `mutation UpdateProjectRole(
  $input: UpdateProjectRoleInput!
  $condition: ModelProjectRoleConditionInput
) {
  updateProjectRole(input: $input, condition: $condition) {
    id
    roleName
    roleDescription
    projectId
    project {
      id
      projectName
      projectDescription
      projectStartDate
      projectEndDate
      projectStatus
      projectSmes
      projectDomains
      projectRoles {
        items {
          id
          roleName
          roleDescription
          projectId
          createdAt
          updatedAt
          projectRoleAssignedToId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    assignedTo {
      id
      profileUsername
      firstName
      lastName
      email
      profileBio
      profileDomains
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    projectRoleAssignedToId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectRoleMutationVariables,
  APITypes.UpdateProjectRoleMutation
>;
export const deleteProjectRole = /* GraphQL */ `mutation DeleteProjectRole(
  $input: DeleteProjectRoleInput!
  $condition: ModelProjectRoleConditionInput
) {
  deleteProjectRole(input: $input, condition: $condition) {
    id
    roleName
    roleDescription
    projectId
    project {
      id
      projectName
      projectDescription
      projectStartDate
      projectEndDate
      projectStatus
      projectSmes
      projectDomains
      projectRoles {
        items {
          id
          roleName
          roleDescription
          projectId
          createdAt
          updatedAt
          projectRoleAssignedToId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    assignedTo {
      id
      profileUsername
      firstName
      lastName
      email
      profileBio
      profileDomains
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    projectRoleAssignedToId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectRoleMutationVariables,
  APITypes.DeleteProjectRoleMutation
>;
export const createProfile = /* GraphQL */ `mutation CreateProfile(
  $input: CreateProfileInput!
  $condition: ModelProfileConditionInput
) {
  createProfile(input: $input, condition: $condition) {
    id
    profileUsername
    firstName
    lastName
    email
    profileBio
    profileDomains
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileMutationVariables,
  APITypes.CreateProfileMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile(
  $input: UpdateProfileInput!
  $condition: ModelProfileConditionInput
) {
  updateProfile(input: $input, condition: $condition) {
    id
    profileUsername
    firstName
    lastName
    email
    profileBio
    profileDomains
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const deleteProfile = /* GraphQL */ `mutation DeleteProfile(
  $input: DeleteProfileInput!
  $condition: ModelProfileConditionInput
) {
  deleteProfile(input: $input, condition: $condition) {
    id
    profileUsername
    firstName
    lastName
    email
    profileBio
    profileDomains
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileMutationVariables,
  APITypes.DeleteProfileMutation
>;
