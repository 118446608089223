import * as React from "react";
import { useState, useEffect } from "react";

import {
  Alert,
  Form,
  SpaceBetween,
  Button,
  Container,
  Header,
  FormField,
  Input,
  Textarea,
  DatePicker,
  Multiselect,
} from "@amzn/awsui-components-react/polaris";

import { createProjectMutation } from "../Utils/ApiCalls/Mutations";

import { ProjectInterface } from "../Interfaces/ProjectInterface";
import { DomainInterface } from "../Interfaces/DomainInterface";
import { fetchAllDomains } from "../Utils/ApiCalls/Queries";
interface ICreateProjectPageProps {}

const CreateProjectForm = ({
  projectData,
  setProjectData,
  selectedOptions,
  setSelectedOptions,
  alertVisible,
  setAlertVisible,
  alertMessage,
  setAlertMessage,
  alertType,
  setAlertType,
  closeAlert,
  handleFormChange,
}: any) => {
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const domainsResult = await fetchAllDomains();
      setDomains(domainsResult);
    }
    fetchData();
  }, []);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setProjectData({ ...projectData, projectDomains: selectedOptions });
    console.log(projectData);
    const result = await createProjectMutation(projectData);
    if (result) {
      setAlertMessage("Your project has been created successfully.");
      setAlertType("success");
      setAlertVisible(true);
      setProjectData({
        projectName: "",
        projectSmes: [],
        projectStartDate: "",
        projectEndDate: "",
        projectStatus: "",
        projectDomains: [],
        projectDescription: "",
      });
      setSelectedOptions([]);

      console.log("result: ", result);
    } else {
      setAlertMessage("Something went wrong. Please try again.");
      setAlertType("error");
      setAlertVisible(true);
    }
  };

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <Form>
        <Container>
          <Alert
            dismissible
            statusIconAriaLabel="Success"
            type={alertType}
            visible={alertVisible}
            onDismiss={closeAlert}>
            {alertMessage}
          </Alert>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Project Name">
              <Input
                onChange={({ detail }) => {
                  handleFormChange("projectName", detail.value);
                }}
                value={projectData.projectName}
                inputMode="text"
                placeholder="Project Name"
              />
            </FormField>
            <FormField label="Project Description">
              <Textarea
                onChange={({ detail }) => {
                  handleFormChange("projectDescription", detail.value);
                }}
                value={projectData.projectDescription}
                placeholder="Tell us more about your project ..."
              />
            </FormField>
            <FormField
              label="Project Start-date"
              constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                onChange={({ detail }) => {
                  handleFormChange("projectStartDate", detail.value);
                }}
                value={projectData.projectStartDate}
                isDateEnabled={(date) =>
                  date.getDay() !== 6 && date.getDay() !== 0
                }
                openCalendarAriaLabel={(selectedDate) =>
                  "Choose certificate expiry date" +
                  (selectedDate ? `, selected date is ${selectedDate}` : "")
                }
                placeholder="YYYY/MM/DD"
              />
            </FormField>
            <FormField
              label="Project End-date"
              constraintText="Use YYYY/MM/DD format.">
              <DatePicker
                onChange={({ detail }) => {
                  handleFormChange("projectEndDate", detail.value);
                }}
                value={projectData.projectEndDate}
                isDateEnabled={(date) =>
                  date.getDay() !== 6 && date.getDay() !== 0
                }
                openCalendarAriaLabel={(selectedDate) =>
                  "Choose certificate expiry date" +
                  (selectedDate ? `, selected date is ${selectedDate}` : "")
                }
                placeholder="YYYY/MM/DD"
              />
            </FormField>
            <FormField label="Project Domains">
              <Multiselect
                selectedOptions={selectedOptions}
                onChange={({ detail }) => {
                  handleFormChange(
                    "projectDomains",
                    detail.selectedOptions.map((option) => option.label)
                  );

                  setSelectedOptions(detail.selectedOptions);
                }}
                options={domains.map((domain: any) => ({
                  label: domain.DomainName,
                  value: domain.id,
                }))}
                placeholder="Select domains"
                tokenLimit={4}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
    </form>
  );
};

export default CreateProjectForm;
