import * as React from "react";
import { useState } from "react";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";

import AnchorNavigation from "@amzn/awsui-components-react/polaris/anchor-navigation";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";

import { useLocation } from "react-router-dom";

interface ISideNavigationBarProps {}

const SideNavigationBar = (props: any) => {
  const { activeHref, setActiveHref } = props;

  return (
    <>
      <SideNavigation
        activeHref={activeHref}
        header={{ href: "/", text: "SME Sourcer" }}
        onFollow={(event) => {
          if (!event.detail.external) {
            // event.preventDefault();
            setActiveHref(event.detail.href);
          }
        }}
        items={[
          {
            type: "link",
            text: "Home",
            href: "/",
          },

          // Project Owner Section
          {
            type: "expandable-link-group",
            defaultExpanded: false,
            text: "Project Owner",
            href: "/",
            items: [
              {
                type: "link",
                text: "Create a Project",
                href: "/create/project",
              },
              {
                type: "link",
                text: "Find SMEs",
                href: "/find/sme",
              },
            ],
          },

          // SME Section
          {
            type: "expandable-link-group",
            defaultExpanded: false,
            text: "SME Pages",
            href: "/",
            items: [
              {
                type: "link",
                text: "Find Projects",
                href: "/find/projects",
              },
              {
                type: "link",
                text: "Profile",
                href: "/user/profile",
              },
            ],
          },

          // Manage Projects Section
          {
            type: "link",
            text: "Manage Projects",
            href: "/projects/manage",
          },
          // SME Sourcer FAQ Page
          {
            type: "link",
            text: "FAQs",
            href: "/projects/faq",
          },
          {
            type: "link",
            text: "About",
            href: "/projects/about",
          },
        ]}
      />
    </>
  );
};

export default SideNavigationBar;
