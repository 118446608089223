import * as React from "react";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import ProjectsTab from "../Components/ProjectsTab";

export default (props: any) => {
  return (
    <Tabs
      tabs={[
        {
          label: "Owned Projects",
          id: "first",
          content: <ProjectsTab type="Owned" user={props.user} />,
        },
        {
          label: "Volunteered Projects",
          id: "second",
          content: <ProjectsTab type="Volunteered" user={props.user} />,
        },
      ]}
    />
  );
};
