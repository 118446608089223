import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  ContentLayout,
  ColumnLayout,
  ExpandableSection,
} from "@amzn/awsui-components-react/polaris";

const FaqPage = (props: any) => {
  const { user } = props;
  const { Auth } = props;
  // Michael's edits
  const navigate = useNavigate();

  const navigateToCreateProject = () => {
    navigate("./create/project");
  };

  const navigateToFindSme = () => {
    navigate("./find/sme");
  };

  const navigateToFindProject = () => {
    navigate("./find/projects");
  };

  const navigateToProfile = () => {
    navigate("./user/profile");
  };

  // end Michael's edits

  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
  }, [user]);

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">
            <div className="">
              <Box padding={{ vertical: "xxxl", horizontal: "s" }}>
                <div>
                  <Box
                    variant="h1"
                    fontWeight="heavy"
                    padding="n"
                    fontSize="display-l"
                    color="inherit"
                  >
                    <span className="">FAQs</span>
                  </Box>
                  <Box variant="p" fontWeight="light">
                    <span className="custom-home__category">
                      Frequently asked questions regarding SME Sourcer and how
                      to use the platform.
                    </span>
                  </Box>
                </div>
              </Box>
            </div>
          </Header>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="m">
        <Container>
          <ColumnLayout variant="text-grid">
            <div>
              <ExpandableSection headerText="What is SME Sourcer?">
                SME Sourcer is a platform that connects project owners and
                Subject Matter Experts (SMEs) to accelerate content development.
                Create a project, add SME requirements, click “Find SMEs” and be
                matched with SMEs in seconds.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection headerText="Who should use SME Sourcer?">
                The two primary types of users on SME Sourcer should be Subject
                Matter Experts (SMEs) and Amazonians with internal projects that
                they would like to find SMEs to help them with. A good example
                of this is the AWS LevelUp internal trainings. The LevelUp team
                needs to find SMEs to help them write scripts with accurate and
                up-to-date information that is relevant to the AWS organization,
                and SMEs want to assist with the LevelUp project. However, since
                so many SMEs across AWS, it is hard for the LevelUp team to find
                the right SMEs and it is hard to SMEs to find up where to
                volunteer for LevelUp. SME Sourcer would be the perfect use case
                for this project.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection headerText="As an SME, how can I volunteer for projects?">
                As an SME, you can volunteer for projects by using the "Find
                Projects" page under the "SME Pages" section. There, you can
                search for upcoming and ongoing projects which you can volunteer
                for. Please review the role requirements for an SME prior to
                volunteering as an SME for a project.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection headerText="As a project owner, how can I find SMEs?">
                As a project owner, you can use the "Create Project" page (under
                the "Project Owner" section) to create a project page for your
                upcoming project. Within the project page, you can specify
                project details, timelines, and what you're looking for in terms
                of SMEs. Then, you can publish your project, and SMEs can find
                it and volunteer for it through this platform.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection headerText="How does SME Sourcer determine the best SMEs for my project?">
                SME Sourcer pulls data from your AWS Skills profile as well as
                TFC data. This is pushed through an AI model hosted in SageMaker
                which determines who, out of the eligible list of SMEs who
                registered for a role in a project, is the best fit.
              </ExpandableSection>
            </div>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default FaqPage;
