import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";

import { fetchAllSmes } from "../Utils/ApiCalls/Queries";

export default ({
  selectedSmes,
  setSelectedSmes,
  smes,
  setsmes,
  loading,
  setLoading,
  handleFormChange,
}: any) => {
  const fetchData = async () => {
    try {
      const allSmes = await fetchAllSmes();
      setsmes(allSmes);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching smes: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("filtered Smes:", selectedSmes);
  console.log("Smes:", smes);
  return (
    <Table
      onSelectionChange={({ detail }) => setSelectedSmes(detail.selectedItems)}
      selectedItems={selectedSmes}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${
            selectedItems.length === 1 ? "item" : "items"
          } selected`,
        itemSelectionLabel: ({ selectedItems }, item) => item.name,
      }}
      columnDefinitions={[
        {
          id: "checkbox",
          header: (
            <input
              type="checkbox"
              checked={selectedSmes.length === smes.length}
              onChange={(e) => {
                const checked = e.target.checked;
                setSelectedSmes(checked ? smes : []);
              }}
            />
          ),
          cell: (e: any) => (
            <input
              type="checkbox"
              checked={selectedSmes.includes(e.id)}
              onChange={() => {
                const newSelectedItems = selectedSmes.includes(e.id)
                  ? selectedSmes.filter((item: any) => item !== e.id)
                  : [...selectedSmes, e.id];
                setSelectedSmes(newSelectedItems);
              }}
            />
          ),
          width: "50px",
          isRowHeader: true,
        },
        {
          id: "smeName",
          header: "SME Name",
          cell: (e: any) => e.profileUsername,
          sortingField: "projectName",
          isRowHeader: true,
        },
        {
          id: "smeDescription",
          header: "SME Description",
          cell: (e: any) => e.profileBio,
        },
      ]}
      columnDisplay={[
        { id: "checkbox", visible: true },
        { id: "smeName", visible: true },
        { id: "smeDescription", visible: true },
      ]}
      items={smes}
      loading={loading}
      loadingText="Loading SMEs"
      trackBy="name"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No SME found!</b>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter filteringPlaceholder="Search SMEs" filteringText="" />
      }
      header={
        <Header
        //   counter={
        //     selectedItems.length ? "(" + selectedItems.length + "/10)" : "(10)"
        //   }
        >
          List of matching SMEs
        </Header>
      }
      pagination={<Pagination currentPageIndex={1} pagesCount={1} />}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: [
              { id: "smeName", visible: true },
              { id: "smeDescription", visible: true },
            ],
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
            ],
          }}
        />
      }
    />
  );
};
