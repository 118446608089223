import * as React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  TagEditor,
  Form,
} from "@amzn/awsui-components-react/polaris";
import { fetchAllSmes } from "../Utils/ApiCalls/Queries";

import { ProjectRoleInterface } from "../Interfaces/ProjectRoleInterface";

const AddRolesForm = ({ setLoading, roles, setRoles }: any) => {
  const fetchData = async () => {
    try {
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form>
        <Container>
          <TagEditor
            i18nStrings={{
              addButton: "Add Role",
              removeButton: "Remove Role",
              keyHeader: "Role",
              valueHeader: "Description",
              optional: "Optional",
              valuePlaceholder: "Role Description...",
            }}
            tags={roles}
            onChange={({ detail }: any) => setRoles(detail.tags)}
            keysRequest={() =>
              Promise.resolve(["Content Creator", "Contributor", "Writer"])
            }
            valuesRequest={() => Promise.resolve([])}
          />
        </Container>
      </Form>
    </form>
  );
};

export default AddRolesForm;
