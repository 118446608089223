import { API, graphqlOperation } from "aws-amplify";
import {
  listProjects,
  getProject,
  listProfiles,
  getProfile,
  listDomains,
  getProfileByProfileUsername,
} from "../../graphql/queries";
import { updateProfile } from "../../graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";

const fetchAllDomains = async () => {
  try {
    const domains = (await API.graphql(
      graphqlOperation(listDomains)
    )) as GraphQLResult<any>;
    return domains?.data?.listDomains?.items;
  } catch (error) {
    console.log(`Error Fetching All Domains`, error);
  }
};

const fetchAllProjects = async () => {
  try {
    const projects = (await API.graphql(
      graphqlOperation(listProjects)
    )) as GraphQLResult<any>;
    return projects?.data?.listProjects?.items;
  } catch (error) {
    console.log(`Error Fetching All projects`, error);
  }
};

const fetchProject = async (id: string) => {
  try {
    const project = (await API.graphql(
      graphqlOperation(getProject, { id })
    )) as GraphQLResult<any>;
    const projectData = project?.data?.getProject;
    const projectRoles = projectData?.projectRoles?.items || [];
    console.log("projectData: ", projectData);
    console.log("projectRoles: ", projectRoles);
    return project?.data?.getProject;
  } catch (error) {
    console.log(`Error Fetching Project`, error);
  }
};

const fetchAllSmes = async () => {
  try {
    const smes = (await API.graphql(
      graphqlOperation(listProfiles)
    )) as GraphQLResult<any>;
    return smes?.data?.listProfiles?.items;
  } catch (error) {
    console.log(`Error Fetching All projects`, error);
  }
};

const fetchSme = async (id: string) => {
  try {
    const profile = (await API.graphql(
      graphqlOperation(getProfile, { id })
    )) as GraphQLResult<any>;
    return profile?.data?.getProfile;
  } catch (error) {
    console.log(`Error Fetching SME`, error);
  }
};

const fetchProfileByUsername = async (profileUsername: string) => {
  try {
    const profile = (await API.graphql(
      graphqlOperation(getProfileByProfileUsername, { profileUsername })
    )) as GraphQLResult<any>;
    return profile;
  } catch (error) {
    console.log(`Error Fetching Project`, error);
  }
};

export {
  fetchAllProjects,
  fetchProject,
  fetchAllSmes,
  fetchSme,
  fetchAllDomains,
  fetchProfileByUsername,
};
