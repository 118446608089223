import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Wizard,
  Link,
  Container,
  Header,
  SpaceBetween,
  FormField,
  Input,
  Box,
  Button,
  ColumnLayout,
} from "@amzn/awsui-components-react/polaris";
import {
  createProjectMutation,
  createProjectRoleMutation,
} from "../Utils/ApiCalls/Mutations";

import CreateProjectForm from "./CreateProjectForm";
import AddRolesForm from "./AddRolesForm";
import ListOfSMEs from "./ListOfSMEs";
import { ProjectInterface } from "../Interfaces/ProjectInterface";
export default () => {
  const [roles, setRoles] = useState([]);
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [projectData, setProjectData] = useState<ProjectInterface>({
    projectName: "",
    // projectSmes: [],
    projectStartDate: "",
    projectEndDate: "",
    projectStatus: "Open",
    projectDomains: [],
    projectDescription: "",
  });
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState<any>("");
  const [alertMessage, setAlertMessage] = useState("");

  const [selectedSmes, setSelectedSmes] = React.useState<any>([]);

  const [smes, setsmes] = useState<any>([]);
  const [filtered_smes, setFilteredsmes] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const closeAlert = () => {
    setAlertVisible(false);
  };

  const handleFormChange = (name: string, value: any) => {
    setProjectData({
      ...projectData,
      [name]: value,
    });
  };
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    // e.preventDefault();
    // setProjectData({ ...projectData, projectDomains: selectedOptions });
    console.log(projectData);
    console.log(roles);
    const result = await createProjectMutation(projectData);
    if (result) {
      const projectId = result.data?.createProject?.id;
      const createRolesResult = await createProjectRoleMutation(
        roles,
        projectId
      );
      setAlertMessage("Your project has been created successfully.");
      setAlertType("success");
      setAlertVisible(true);
      setProjectData({
        projectName: "",
        projectSmes: [],
        projectStartDate: "",
        projectEndDate: "",
        projectStatus: "",
        projectDomains: [],
        projectDescription: "",
      });
      setSelectedOptions([]);

      console.log("result: ", result.data?.createProject?.id);
      navigate(`/project/${result.data?.createProject?.id}`);
    } else {
      setAlertMessage("Something went wrong. Please try again.");
      setAlertType("error");
      setAlertVisible(true);
    }
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          `Step ${stepNumber} of ${stepsCount}`,
        skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
        navigationAriaLabel: "Steps",
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Create Project",
        optional: "optional",
      }}
      onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
      onSubmit={() => handleFormSubmit()}
      activeStepIndex={activeStepIndex}
      allowSkipTo
      steps={[
        {
          title: "Create Project",
          content: (
            <CreateProjectForm
              projectData={projectData}
              setProjectData={setProjectData}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              handleFormChange={handleFormChange}
              alertVisible={alertVisible}
              alertType={alertType}
              alertMessage={alertMessage}
              setAlertVisible={setAlertVisible}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              closeAlert={closeAlert}
            />
          ),
        },
        {
          title: "Add Roles",
          content: (
            <AddRolesForm
              smes={smes}
              setsmes={setsmes}
              filtered_smes={filtered_smes}
              setFilteredsmes={setFilteredsmes}
              loading={loading}
              setLoading={setLoading}
              roles={roles}
              setRoles={setRoles}
            />
          ),
          isOptional: true,
        },
        {
          title: "Invite SMEs",
          content: (
            <ListOfSMEs
              selectedSmes={selectedSmes}
              setSelectedSmes={setSelectedSmes}
              smes={smes}
              setsmes={setsmes}
              filtered_smes={filtered_smes}
              setFilteredsmes={setFilteredsmes}
              loading={loading}
              setLoading={setLoading}
              roles={roles}
              setRoles={setRoles}
              handleFormChange={handleFormChange}
            />
          ),
          isOptional: true,
        },
      ]}
    />
  );
};
