import * as React from "react";
import { useState } from "react";

import {
  Alert,
  Form,
  SpaceBetween,
  Button,
  Container,
  Header,
  FormField,
  Input,
  Textarea,
  DatePicker,
  Multiselect,
} from "@amzn/awsui-components-react/polaris";

import { createProjectMutation } from "../Utils/ApiCalls/Mutations";

import { ProjectInterface } from "../Interfaces/ProjectInterface";
import { DomainInterface } from "../Interfaces/DomainInterface";
import CreateProjectWizard from "../Components/CreateProjectWizard";
interface ICreateProjectPageProps {}

const CreateProjectPage: React.FunctionComponent<ICreateProjectPageProps> = (
  props
) => {
  return (
    <div>
      <CreateProjectWizard />
    </div>
  );
};

export default CreateProjectPage;
