import * as React from "react";

import { useState, useEffect } from "react";

import {
  Badge,
  Table,
  Box,
  Checkbox,
  SpaceBetween,
  TextFilter,
  Header,
  Pagination,
  CollectionPreferences,
  Link,
} from "@amzn/awsui-components-react/polaris";

import { fetchAllSmes } from "../Utils/ApiCalls/Queries";

interface IFindSmePageProps {}

const renderDomains = (domains: any) => {
  return domains.map((domain: string, index: number) => (
    <SpaceBetween direction="horizontal" size="xs">
      <Badge color="blue" key={index}>
        {domain}
      </Badge>
    </SpaceBetween>
  ));
};

const renderStatus = (status: string) => {
  return status === "Open" ? (
    <Badge color="green">{status}</Badge>
  ) : (
    <Badge color="red">{status}</Badge>
  );
};

const FindSmePage: React.FunctionComponent<IFindSmePageProps> = (props) => {
  const [projects, setProjects] = useState<any>([]);
  const [filtered_projects, setFilteredProjects] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const [checked5, setChecked5] = React.useState(false);
  const [checked6, setChecked6] = React.useState(false);
  const fetchData = async () => {
    try {
      const allSmes = await fetchAllSmes();
      console.log("allProjects: ", allSmes);
      setProjects(allSmes);
      setFilteredProjects(allSmes);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching projects: ", error);
    }
  };

  const setFilter = (domain: string, rating: number) => {
    const filtered = projects.filter((sme: any) => {
      return sme[domain + "Rating"] >= rating;
    });
    setFilteredProjects(filtered);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Table
        columnDefinitions={[
          {
            id: "smeName",
            header: "SME Name",
            cell: (e: any) => e.profileUsername,
            sortingField: "projectName",
            isRowHeader: true,
          },
          {
            id: "smeDescription",
            header: "SME Description",
            cell: (e: any) => e.profileBio,
          },
        ]}
        columnDisplay={[
          { id: "smeName", visible: true },
          { id: "smeDescription", visible: true },
        ]}
        items={filtered_projects}
        loading={loading}
        loadingText="Loading SMEs"
        stickyHeader
        variant="full-page"
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No SMEs Available</b>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter filteringPlaceholder="Search SMEs" filteringText="" />
        }
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Checkbox
                  onChange={({ detail }) => {
                    setChecked1(detail.checked);
                    if (detail.checked) {
                      setFilter("analytics", 3);
                    } else {
                      setFilteredProjects(projects);
                    }
                  }}
                  checked={checked1}>
                  Analytics
                </Checkbox>
                <Checkbox
                  onChange={({ detail }) => {
                    setChecked2(detail.checked);
                    if (detail.checked) {
                      setFilter("storage", 3);
                    } else {
                      setFilteredProjects(projects);
                    }
                  }}
                  checked={checked2}>
                  Storage
                </Checkbox>
                <Checkbox
                  onChange={({ detail }) => {
                    setChecked3(detail.checked);
                    if (detail.checked) {
                      setFilter("serverless", 3);
                    } else {
                      setFilteredProjects(projects);
                    }
                  }}
                  checked={checked3}>
                  Serverless
                </Checkbox>
                <Checkbox
                  onChange={({ detail }) => {
                    setChecked4(detail.checked);
                    if (detail.checked) {
                      setFilter("containers", 3);
                    } else {
                      setFilteredProjects(projects);
                    }
                  }}
                  checked={checked4}>
                  Containers
                </Checkbox>
                <Checkbox
                  onChange={({ detail }) => {
                    setChecked5(detail.checked);
                    if (detail.checked) {
                      setFilter("networking", 3);
                    } else {
                      setFilteredProjects(projects);
                    }
                  }}
                  checked={checked5}>
                  Networking
                </Checkbox>
                <Checkbox
                  onChange={({ detail }) => {
                    setChecked6(detail.checked);
                    if (detail.checked) {
                      setFilter("security", 3);
                    } else {
                      setFilteredProjects(projects);
                    }
                  }}
                  checked={checked6}>
                  Security
                </Checkbox>
              </SpaceBetween>
            }>
            Find SMEs
          </Header>
        }
        pagination={<Pagination currentPageIndex={1} pagesCount={1} />}
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              contentDisplay: [
                { id: "smeName", visible: true },
                { id: "smeDescription", visible: true },
              ],
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" },
              ],
            }}
          />
        }
      />
    </div>
  );
};

export default FindSmePage;
