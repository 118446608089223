import { API, graphqlOperation } from "aws-amplify";
import {
  createDomain,
  createProject,
  createProjectRole,
  updateProject,
} from "../../graphql/mutations";

// Profile mutations from mutation.ts
import { createProfile, updateProfile } from "../../graphql/mutations";

import { GraphQLQuery } from "@aws-amplify/api";

import {
  CreateProjectInput,
  CreateProjectMutation,
  UpdateProjectMutation,
  DeleteProjectMutation,
  // Profile mutations
  CreateProfileInput,
  CreateProfileMutation,
  UpdateProfileMutation,
  DeleteProfileMutation,
  CreateDomainMutation,
  CreateDomainInput,
  CreateProjectRoleMutation,
  CreateProjectRoleInput,
} from "../../GraphQlApi";

// Create Domain mutation
const createDomainMutation = (input: CreateDomainInput) => {
  try {
    const result = API.graphql<GraphQLQuery<CreateDomainMutation>>(
      graphqlOperation(createDomain, { input: input })
    );
    return result;
  } catch (error: any) {
    console.error("GraphQL operation error:", error?.errors[0]);
  }
};

// Create project mutation
const createProjectMutation = async (input: CreateProjectInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<CreateProjectMutation>>(
      graphqlOperation(createProject, { input: input })
    );
    return result;
  } catch (error: any) {
    console.error("GraphQL operation error:", error);
  }
};

// Update project mutation
const updateProjectMutation = (input: CreateProjectInput) => {
  const result = API.graphql<GraphQLQuery<UpdateProjectMutation>>(
    graphqlOperation(updateProject, { input: input })
  );
  return result;
};

// Create profile mutation
const createProfileMutation = async (input: CreateProfileInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<CreateProfileMutation>>(
      graphqlOperation(createProfile, { input: input })
    );
    return result;
  } catch (error: any) {
    console.error("GraphQL operation error:", error?.errors[0]);
  }
};

// Create ProjectRole mutation

const createProjectRoleMutation = async (roles: any, projectId: any) => {
  const promises = roles.map(async (role: any) => {
    const input = {
      roleName: role.key,
      roleDescription: role.value,
      projectId: projectId,
    };

    try {
      return await API.graphql<GraphQLQuery<CreateProjectRoleMutation>>(
        graphqlOperation(createProjectRole, { input })
      );
    } catch (error) {
      // throw error;
      console.error("GraphQL operation error:", error);
    }
  });

  return Promise.all(promises);
};

// Update profile mutation
const updateProfileMutation = async (input: UpdateProfileMutation) => {
  try {
    const result = await API.graphql<GraphQLQuery<UpdateProfileMutation>>(
      graphqlOperation(updateProfile, { input: input })
    );
    return result;
  } catch (error: any) {
    console.error("GraphQL operation error:", error?.errors[0]);
  }
};

export {
  createProjectMutation,
  createProfileMutation,
  updateProfileMutation,
  createDomainMutation,
  createProjectRoleMutation,
};
