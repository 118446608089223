import { createTableSortLabelFn } from './columnsfunctions';
import Link from '@amzn/awsui-components-react/polaris/link';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Box from '@amzn/awsui-components-react/polaris/box';

const rawColumns = [
  {
    id: "createDate",
    header: "Created Date",
    cell: item => item.createDate,
    sortingField: "createDate",
    // minWidth: 100,
  },
  {
    // minWidth: "10",
    id: "name",
    header: "Name",
    cell: (item) => (
      <Link href={`/project/${item.id}`}>{item.projectName}</Link>
    ),
    // sortingField: "name",
    minWidth: 100,
  },
  
  {
    id: "startdate",
    header: "Start Date",
    cell: item => item.projectStartDate,
    // sortingField: "filename",
    minWidth: 100,
  },
  {
    id: "enddate",
    header: "End Date",
    cell: item => item.projectEndDate,
    // sortingField: "filename",
    minWidth: 100,
  },
  {
    id: "projectdomains",
    header: "Project Domains",
    cell: item => item.projectDomains,
    // sortingField: "filename",
    minWidth: 100,
  },
  {
    id: "description",
    header: "Description",
    cell: item => item.projectDescription,
    minWidth: 100,
  },
  {
    id: "costSavings",
    header: "Cost Savings",
    cell: item => item.costSavings == 0 ? <Box textAlign="center" fontSize="body-m">N/A</Box> : <Box textAlign="center" color="text-status-success" fontSize="body-m" fontWeight="bold" >${item.costSavings}</Box>,
    // sortingField: "costSavings",
    minWidth: 100,
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));