import * as React from "react";
import { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";

import {
  createProfileMutation,
  updateProfileMutation,
} from "../Utils/ApiCalls/Mutations";

import { ProfileInterface } from "../Interfaces/ProfileInterface";

import FormatUsername from "../Utils/FormatUsername";

import {
  Alert,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Textarea,
  DatePicker,
  Multiselect,
  ColumnLayout,
  Spinner,
} from "@amzn/awsui-components-react/polaris";

import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import BarChart from "@amzn/awsui-components-react/polaris/bar-chart";
import Box from "@amzn/awsui-components-react/polaris/box";

import CheckIfUserExist from "../Utils/CheckIfUserExist";
import CompleteProfileForm from "../Components/CompleteProfileForm";
import { fetchSme } from "../Utils/ApiCalls/Queries";
import renderDomains from "../Components/RenderDomains";

interface IProfilePageProps {}

const ProfilePage = (props: any) => {
  const { Auth, user } = props;
  const [userProfile, setUserProfile] = useState<any>();
  const [userExists, setUserExists] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const { attributes, username } = user;
    const { given_name, family_name, email, sub } = attributes;
    const checkUser = async () => {
      const userExist = await CheckIfUserExist(sub);
      setUserExists(userExist);
      if (userExist) {
        // User has a profile
        console.log("user exists");
        const getSmeProfile = async () => {
          const userProfileData = await fetchSme(sub);

          setUserProfile(userProfileData);
        };
        getSmeProfile();
      } else {
        // User does not have a profile yet
        console.log("user does not exist");

        if (user) {
          if (attributes) {
            const userProfileData = {
              id: sub,
              firstName: given_name,
              lastName: family_name,
              profileUsername: FormatUsername(username),
              email: email,
              profileBio: "",
            };
            setUserProfile(userProfileData);
          }
        }
      }
    };

    checkUser().finally(() => {
      setLoading(false);
    });
  }, [user]);

  return (
    <>
      {/* <Container
        header={
          <Header
            variant="h2"
            description="This is your space to tell people about yourself. Project owners will see this information about you when selecting SMEs.">
            Hello, Welcome to your profile page.
          </Header>
        }>
        <SpaceBetween direction="vertical" size="m">
          <BarChart
            series={[
              {
                title: "Self-reported domain competency",
                type: "bar",
                data: [
                  { x: "Serverless", y: 0 },
                  { x: "Analytics", y: 1 },
                  { x: "Containers", y: 2 },
                  { x: "Storage", y: 3 },
                  { x: "Networking", y: 4 },
                  { x: "Security", y: 5 },
                ],
                valueFormatter: (e) => "" + e.toLocaleString("en-US"),
              },
              {
                title: "Project Verified Domain Competency",
                type: "threshold",
                y: 0,
                valueFormatter: (e) => "" + e.toLocaleString("en-US"),
              },
            ]}
            xDomain={[
              String("Serverless"),
              String("Analytics"),
              String("Containers"),
              String("Storage"),
              String("Networking"),
              String("Containers"),
              String("Security"),
            ]}
            yDomain={[0, 5]}
            ariaLabel="Single data series line chart"
            height={300}
            hideFilter
            xTitle="Domains"
            yTitle="Domain Competency"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No data available</b>
                <Box variant="p" color="inherit">
                  There is no data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign="center" color="inherit">
                <b>No matching data</b>
                <Box variant="p" color="inherit">
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
        </SpaceBetween>
      </Container> */}
      <br />
      {loading && <Spinner />}
      {!loading && userExists ? (
        <Container>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">First Name</Box>
                <div>{userProfile?.firstName}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Last Name</Box>
                <div>{userProfile?.lastName}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Profile Username</Box>
                <div>{userProfile?.profileUsername}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Email</Box>
                <div>{userProfile?.email}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Profile Bio</Box>
                <div>{userProfile?.profileBio}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Profile Domains</Box>
                <div>{userProfile?.profileDomains}</div>
                {/* <div>{renderDomains(userProfile?.profileDomains)}</div> */}
              </div>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      ) : (
        <CompleteProfileForm
          user={user}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
        />
      )}
    </>
  );
};

export default ProfilePage;
