import * as React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  Tabs,
  Table,
} from "@amzn/awsui-components-react/polaris";

import {
  fetchProject,
  fetchProfileByUsername,
} from "../Utils/ApiCalls/Queries";
import { checkIfUserProjectOwner } from "../Utils/CheckIfUserIsProjectOwner";
import FormatUsername from "../Utils/FormatUsername";
import { extractUserId } from "../Utils/ExtractUserId";
import { fetchSme } from "../Utils/ApiCalls/Queries";
import renderDomains from "../Components/RenderDomains";

const ProjectPage = ({ user, Auth }: any) => {
  const [project, setProject] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isUserProjectOwner, setIsProjectOwner] = useState(false);
  const [projectRoles, setProjectRoles] = useState<any>();
  const [projectOwner, setProjectOwner] = useState<any>();

  const { id } = useParams();

  const fetchData = async (id: any) => {
    try {
      const project = await fetchProject(id);
      // console.log("user: ", user?.attributes?.sub);
      console.log("Project: ", project);
      setIsProjectOwner(
        checkIfUserProjectOwner(
          FormatUsername(project?.owner),
          FormatUsername(user?.username)
        )
      );
      const ownerProfile = await fetchSme(user?.attributes?.sub);
      console.log("ownerUsername: ", FormatUsername(project?.owner));
      console.log("ownerProfile: ", ownerProfile);
      const owner = [
        {
          alias: ownerProfile?.profileUsername,
          firstName: ownerProfile?.firstName,
          lastName: ownerProfile?.lastName,
        },
      ];
      // console.log("owner: ", owner);
      setProjectOwner(owner);
      setProjectRoles(project?.projectRoles?.items);
      setProject(project);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  const renderStatus = (status: string) => {
    return status === "Open" ? (
      <Badge color="green">{status}</Badge>
    ) : (
      <Badge color="red">{status}</Badge>
    );
  };

  const renderProjectRoles = (projectRoles: any[]) => {
    return (
      <div>
        <div>
          <Box variant="awsui-key-label">Project Roles</Box>
        </div>
        <ul>
          {projectRoles.map((role) => (
            <div key={role.id}>
              <li>
                <strong>{role.roleName}: </strong>
                {role.roleDescription}
              </li>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  const renderProfiles = (profiles: any) => {
    return (
      <Table
        columnDefinitions={[
          {
            id: "alias",
            header: "Alias",
            cell: (item: any) => item.alias || "-",
            sortingField: "alias",
            isRowHeader: true,
          },
          {
            id: "name",
            header: "Full name",
            cell: (item: any) => item.firstName + " " + item.lastName || "-",
            sortingField: "name",
          },
        ]}
        items={profiles}
        loadingText="Loading resources"
        sortingDisabled
        stripedRows
        variant="embedded"
        wrapLines
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No SME</b>
            </SpaceBetween>
          </Box>
        }
      />
    );
  };

  const renderProfileOwner = (profiles: any) => {
    return (
      <Table
        columnDefinitions={[
          {
            id: "alias",
            header: "Alias",
            cell: (item: any) => item.alias || "-",
            sortingField: "alias",
            isRowHeader: true,
          },
          {
            id: "name",
            header: "Full name",
            cell: (item: any) => item.firstName + " " + item.lastName || "-",
            sortingField: "name",
          },
        ]}
        items={profiles}
        loadingText="Loading resources"
        sortingDisabled
        stripedRows
        variant="embedded"
        wrapLines
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No SME</b>
            </SpaceBetween>
          </Box>
        }
      />
    );
  };

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <ContentLayout
          header={
            <SpaceBetween size="m">
              <Header variant="h2">{project?.projectName}</Header>
              {!isUserProjectOwner ? (
                <Button variant={"primary"}>Request to Contribute</Button>
              ) : null}
            </SpaceBetween>
          }>
          <SpaceBetween size="l">
            <Container>
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                  <div>
                    <Box variant="awsui-key-label">Project Title</Box>
                    <div>{project?.projectName}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Project Status</Box>
                    <div>{renderStatus(project?.projectStatus)}</div>
                  </div>
                  <div>{renderProjectRoles(projectRoles)}</div>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <div>
                    <Box variant="awsui-key-label">Start Date</Box>
                    <div>{project?.projectStartDate}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">End Date</Box>
                    <div>{project?.projectEndDate}</div>
                  </div>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <div>
                    <Box variant="awsui-key-label">Domains</Box>
                    <div>{renderDomains(project?.projectDomains)}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Project Description</Box>
                    <div>{project?.projectDescription}</div>
                  </div>
                </SpaceBetween>
              </ColumnLayout>
            </Container>
            <Tabs
              tabs={[
                {
                  label: "Project Owner",
                  id: "owner",
                  content: renderProfileOwner(projectOwner),
                },
                {
                  label: "Project SMEs",
                  id: "smes",
                  content: renderProfiles(project?.smes),
                },
              ]}
              variant="container"
            />
          </SpaceBetween>
        </ContentLayout>
      )}
    </div>
  );
};

export default ProjectPage;
