import * as React from "react";
import { useState, useEffect } from "react";

import { TopNavigation, Button } from "@amzn/awsui-components-react/polaris";
import aws_logo from "../Assets/images/AWS_logo_RGB_REV.png";

const LoginButton = () => {
  return <Button variant="primary">Login</Button>;
};

const iWorkPrompt = () => {
  console.log("I work");
};

const TopNavigationBar = (props: any) => {
  const { user } = props;
  const { Auth } = props;

  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
  }, [user]);

  return (
    <TopNavigation
      identity={{
        href: "#",
        title: "SME-Sourcer",
        logo: {
          src: aws_logo,
        },
      }}
      utilities={
        user
          ? [
              {
                type: "button",
                iconName: "notification",
                title: "Notifications",
                ariaLabel: "Notifications (unread)",
                badge: true,
                disableUtilityCollapse: false,
              },
              {
                type: "menu-dropdown",
                text: `${given_name} ${family_name}`,
                description: `${email}`,
                iconName: "user-profile",
                onItemClick: () => Auth.signOut(),
                items: [
                  {
                    id: "signout",
                    text: "Sign out",
                  },
                ],
              },
            ]
          : [
              {
                type: "button",
                text: "Login",
                onClick: () => {
                  Auth.federatedSignIn({ customProvider: "FederateOIDC" });
                },
              },
            ]
      }
    />
  );
};

export default TopNavigationBar;
