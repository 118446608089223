import { AppLayout, Button } from "@amzn/awsui-components-react/polaris";

import React, { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import TopNavigationBar from "./App_Layout/TopNavigationBar";
import SideNavigationBar from "./App_Layout/SideNavigationBar";

import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import HomePage from "./Pages/HomePage";
import CreateProjectPage from "./Pages/CreateProjectPage";
import CurrentProjectsPage from "./Pages/CurrentProjectsPage";
import PastProjectsPage from "./Pages/PastProjectsPage";
import ProfilePage from "./Pages/ProfilePage";
import ProjectPage from "./Pages/ProjectPage";
import FindProjectPage from "./Pages/FindProjectPage";
import ManageProjects from "./Pages/ManageProjects";
import FindSmePage from "./Pages/FindSmePage";
import FaqPage from "./Pages/FaqPage";
import AboutPage from "./Pages/AboutPage";
import SecurityPage from "./Pages/Security";
import CheckIfUserExist from "./Utils/CheckIfUserExist";

// import SeedDomains from "./Utils/SeedDomains";

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);

const whiteListedUsers = [
  "federateoidc_fishimwe",
  "federateoidc_manemalh",
  "federateoidc_mdzhang",
  "federateoidc_hugotp",
  "federateoidc_rudchett",
  "federateoidc_protsivd",
  "federateoidc_hanihang",
  "federateoidc_debarrow",
  "federateoidc_adikaris"
];

const isUserWhiteListed = (user: any) => {
  return whiteListedUsers.includes(user?.username);
};

/* This button is a hack and should never be used other than populating domains in the DB */
// const ScaffoldingButton = () => (
//   <button
//     onClick={async () => {
//       try {
//         // Domains Seeding
//         await SeedDomains();
//         console.log("Domains seeded successfully!");
//       } catch (error) {
//         console.error("Error seeding domains:", error);
//       }
//     }}>
//     Run Scaffolding
//   </button>
// );

const AppRouter = (props: any) => {
  const { user } = props;

  return (
    <div>
      {user ? (
        isUserWhiteListed(user) ? (
          <Router>
            <Routes>
              {/* AppSec Security page */}
              <Route
                path="/.well-known/security.txt"
                element={<SecurityPage />}
              />
              <Route path="/" element={<HomePage user={user} Auth={Auth} />} />
              <Route path="/create/project" element={<CreateProjectPage />} />
              <Route
                path="/current/projects"
                element={<CurrentProjectsPage />}
              />
              <Route path="/past/projects" element={<PastProjectsPage />} />
              <Route
                path="/user/profile"
                element={<ProfilePage user={user} Auth={Auth} />}
              />
              <Route
                path="/project/:id"
                element={<ProjectPage user={user} Auth={Auth} />}
              />
              <Route
                path="/find/projects"
                element={<FindProjectPage user={user} />}
              />
              <Route path="/find/sme" element={<FindSmePage />} />
              <Route
                path="/projects/manage"
                element={<ManageProjects user={user} Auth={Auth} />}
              />
              <Route
                path="/projects/faq"
                element={<FaqPage user={user} Auth={Auth} />}
              />
              <Route
                path="/projects/about"
                element={<AboutPage user={user} Auth={Auth} />}
              />
            </Routes>
          </Router>
        ) : (
          <>
            {/* AppSec Security page */}
            <Router>
              <Routes>
                <Route
                  path="/.well-known/security.txt"
                  element={<SecurityPage />}
                />
              </Routes>
            </Router>
            <div>Not Authorized!</div>
          </>
        )
      ) : (
        <>
          {/* AppSec Security page */}
          <Router>
            <Routes>
              <Route
                path="/.well-known/security.txt"
                element={<SecurityPage />}
              />
            </Routes>
          </Router>
          <div>Not Authenticated</div>
        </>
      )}
    </div>
  );
};

const App = () => {
  // Amazon Federate Midway athentication
  const [user, setUser] = useState(null);
  const [activeHref, setActiveHref] = useState("/");
  const [showProfileRegistrationModel, setShowProfileRegistrationModel] =
    useState(false);

  const UserProfileRegistrationModel = () => {
    return (
      <Modal
        onDismiss={() => setShowProfileRegistrationModel(false)}
        visible={showProfileRegistrationModel}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => setShowProfileRegistrationModel(false)}>
                Not yet
              </Button>
              <Button variant="primary" href="/user/profile">
                Complete Profile
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Complete Your Profile and Become an SME">
        It appears you haven't created your profile to become an SME. Would you
        like to complete it now?
      </Modal>
    );
  };
  // getUser function
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  // Use effect for auth
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          getUser().then(async (userData) => {
            const userExists = await CheckIfUserExist(
              userData?.attributes?.sub
            );

            setUser(userData);

            if (!userExists) {
              setTimeout(() => {
                setShowProfileRegistrationModel(true);
              }, 3000);
            }
          });

          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    getUser().then((userData) => setUser(userData));
  }, []);
  return (
    <div>
      <TopNavigationBar user={user} Auth={Auth} />
      <UserProfileRegistrationModel />
      <AppLayout
        navigation={
          user && isUserWhiteListed(user) ? (
            <SideNavigationBar
              activeHref={activeHref}
              setActiveHref={setActiveHref}
            />
          ) : null
        }
        navigationHide={user && isUserWhiteListed(user) ? false : true}
        toolsHide={true}
        content={<AppRouter user={user} />}
      />
    </div>
  );
};

export default App;
