import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  ContentLayout,
  ColumnLayout,
  ExpandableSection,
} from "@amzn/awsui-components-react/polaris";

interface IHomePageProps {}

const HomePage = (props: any) => {
  const { user } = props;
  const { Auth } = props;
  // Michael's edits
  const navigate = useNavigate();

  const navigateToCreateProject = () => {
    navigate("./create/project");
  };

  const navigateToFindSme = () => {
    navigate("./find/sme");
  };

  const navigateToFindProject = () => {
    navigate("./find/projects");
  };

  const navigateToProfile = () => {
    navigate("./user/profile");
  };

  const navigateToAbout = () => {
    navigate("./projects/about");
  };

  // end Michael's edits

  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const { attributes } = user;
      if (attributes) {
        const { given_name, family_name, email } = attributes;
        setGivenName(given_name || "");
        setFamilyName(family_name || "");
        setEmail(email || "");
      }
    }
  }, [user]);

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">
            <div className="">
              <Box padding={{ vertical: "xxxl", horizontal: "s" }}>
                <Grid
                  gridDefinition={[
                    { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                    {
                      colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                      offset: { l: 2, xxs: 1 },
                    },
                  ]}>
                  <Box fontWeight="light" padding={{ top: "xs" }}>
                    <span className="custom-home__category">
                      Hi, {given_name} {family_name}
                    </span>
                  </Box>
                  <div>
                    <Box
                      variant="h1"
                      fontWeight="heavy"
                      padding="n"
                      fontSize="display-l"
                      color="inherit">
                      <span className="">SME Sourcer</span>
                    </Box>
                    <Box
                      fontWeight="light"
                      padding={{ bottom: "s" }}
                      fontSize="display-l"
                      color="inherit">
                      <span className="custom-home__category">
                        Find Subject Matter Experts (SMEs) in a few clicks!
                      </span>
                    </Box>
                    <Box variant="p" fontWeight="light">
                      <span className="custom-home__category">
                        SME Sourcer connects project owners and Subject Matter
                        Experts to accelerate content development. Create a
                        project, add SME requirements, click “Find SMEs” and be
                        matched with SMEs in seconds.
                      </span>
                    </Box>
                  </div>
                  <div>
                    <Container
                      header={
                        <Header
                          variant="h2"
                          description="Create a project as a project owner">
                          Project Owner
                        </Header>
                      }>
                      <Button
                        onClick={navigateToCreateProject}
                        variant="primary">
                        Create Project
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button onClick={navigateToFindSme} variant="primary">
                        Find SMEs
                      </Button>
                    </Container>
                    <br />
                    <Container
                      header={
                        <Header
                          variant="h2"
                          description="Find a project as a SME">
                          SME
                        </Header>
                      }>
                      <Button onClick={navigateToFindProject} variant="primary">
                        Find Projects
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Button onClick={navigateToProfile} variant="primary">
                        Profile
                      </Button>
                    </Container>
                  </div>
                </Grid>
              </Box>
            </div>
          </Header>
        </SpaceBetween>
      }>
      <SpaceBetween size="m">
        <Container
          header={
            <Header variant="h1" description="">
              How it works
            </Header>
          }>
          <div>
            <p>
              Project owners can create a project, define SME requirements,
              setup parameters and then run an algorithm to find SMEs in an
              efficient and streamlined manner. Alternatively, project owners
              can search all registered SMEs for fine-grained access.
            </p>
            <p>
              On the flip side, SMEs can setup a profile, list their areas of
              expertise and then be eligible to be allocated to a project.
              Additionally, SMEs can search through a list of existing projects
              and volunteer.
            </p>
            <Button onClick={navigateToAbout} variant="primary">
              Step-by-step Instructions
            </Button>
          </div>
        </Container>

        <Container
          header={
            <Header variant="h1" description="">
              Use Cases
            </Header>
          }>
          <ColumnLayout columns={2} variant="text-grid">
            <div>
              <ExpandableSection defaultExpanded headerText="Find SMEs Quickly">
                Utilize “SME Search“ when you want to find SMEs in a hurry. This
                feature allows you to search through all registered SMEs and
                specify criteria to narrow the focus.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection defaultExpanded headerText="Publish Projects">
                Utilize “Create Project” if you have a project with specific
                requirements and a need for several SMEs. The tool will pull
                from a list of eligible SMEs based on the requirements you
                specify.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection defaultExpanded headerText="Discover Projects">
                For SMEs, create a profile and then use the “Find Projects” page
                to find projects to contribute to. Even better, earn rewards for
                every project you successfully contribute to.
              </ExpandableSection>
            </div>
            <div>
              <ExpandableSection defaultExpanded headerText="Earn Rewards">
                Successfully contribute to projects and earn rewards like Phone
                Tool icons and badges!
              </ExpandableSection>
            </div>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default HomePage;
